import { NgForm } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommomService } from 'src/app/commom.service';

@Component({
  selector: 'app-homecare',
  templateUrl: './homecare.component.html',
  styleUrls: ['./homecare.component.scss']
})
export class HomecareComponent implements OnInit {

  homeCareModel:any = {};
  isSubmitted = false;
  propertiesType: any = [
    "House/Villa",
    "Commercial Property",
    "Office",
    "Retail",
    "Storage",
    "Hospitality",
    "Apartment",
    "Studio",
    "Showroom",
    "Warehouses",
    "Plot & Farmhouse",
    "Builder Multi Unit",
    "Paying Guest"
  ];
  hostUrl: string;
  hostname: string;
  url: string;
  baseUrl: string;
  postRequirementModel: any;
  type:any;
  categories = [];

  constructor(
    public commonService: CommomService,
    private toastr: ToastrService,
  ) { 
    this.homeCareModel = {
      service: ''
    }
  }

  ngOnInit(): void {
    this.getBaseUrl();
    this.getServices();
  }

  getBaseUrl(){
    this.hostname = window.location.hostname;
    if(this.hostname === "localhost") {
      this.url = "http://localhost:8000";
    }else if(this.hostname === "rentkochi.com") {
      this.url = "https://rentkochi.easerent.com"
    }
    else {
      this.url = "https://" + this.hostname;
    }
  }

  getServices() {
    this.commonService.get<any>(this.url + '/api/EaseRent/sr/categories').subscribe((res:any)  => {
      if(res.Status === '200') {
        this.categories = res.data.categories;
      }
    })
  }

  sendHomeCare(homeCareForm: NgForm) {
    this.baseUrl = this.url + '/api/EaseRent/addAllForm';
    if (homeCareForm.invalid) {
      this.isSubmitted = true;
      return;
    }

    this.isSubmitted = false;
    this.commonService.showLoading();
    const allForm = {
      contactUsBean:  {
        email: null,
        message: null,
        name: null,
        phone: null,
        subject: null
      },
      commercialrentBean: {
        contactno: null,
        emailId: null,
        location: null,
        name: null,
        price: null,
        requirements: null,
        sqfeet: null,
        whatsapp: null
      },
      homeCareBean:this.homeCareModel,
      postRequirementBean:{
        budget: null,
        contactNumber: null,
        emailId: null,
        location: null,
        name: null,
        requirements: null,
        whatsappNumber: null
      },
      careTakerBean:{
        bhk: null,
        contactno: null,
        description: null,
        emailId: null,
        name: null,
        propertyType: null,
        propertyname: null,
        whatsapp: null
      },
      serviceVillaBean: {
        bhk: null,
        contactno: null,
        days: null,
        emailId: null,
        fromdate: null,
        location: null,
        name: null,
        persons: null,
        price: null,
        requirements: null,
        todate: null,
        whatsapp: null
      },
      submitPropertyBean: {
        advanceAmt: null,
        bhk: null,
        commercial: null,
        contactNumber: null,
        description: null,
        emailId: null,
        location: null,
        maintenance: null,
        owner: null,
        propertyName: null,
        propertyType: null,
        rent: null,
        residential: null,
        whatsapp: null
      }
    }

    this.commonService.post(this.baseUrl, allForm).subscribe((res) => {
      if (res.Status === '200') {
        this.toastr.success("Success", "Thanks for your inquiry. Someone from our team will reach out to you soon !!")
      } else if (res.Status === '400') {
        this.toastr.error('Error', res.message)
      }
      homeCareForm.reset();
      this.homeCareModel = {
        service: ''
      }
      this.commonService.hideLoading();
    });
  }
}
