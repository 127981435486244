import { NgForm } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommomService } from 'src/app/commom.service';

@Component({
  selector: 'app-caretaker',
  templateUrl: './caretaker.component.html',
  styleUrls: ['./caretaker.component.scss']
})
export class CaretakerComponent implements OnInit {

  careTakerModel:any = {};
  isSubmitted = false;
  propertiesType: any = [
    "Residential",
    "Commercial"
  ];

  bhkList:any = [
    "1BHK",
    "2BHK",
    "3BHK",
    "4BHK",
  ];

  type:any;
  bhk:any;
  hostUrl: string;
  hostname: string;
  url: string;
  baseUrl: string

  constructor(
    public commonService: CommomService,
    private toastr: ToastrService,
  ) { 
    this.careTakerModel = {
      propertyType:'',
      bhk:''
    }
  }

  ngOnInit(): void {
    this.getBaseUrl();
  }

  getBaseUrl(){
    this.hostname = window.location.hostname;
    if(this.hostname === "localhost") {
      this.url = "http://localhost:8000";
    }else if(this.hostname === "rentkochi.com") {
      this.url = "https://rentkochi.easerent.com"
    }
    else {
      this.url = "https://" + this.hostname;
    }
  }

  sendCarfeTaker(careTakerForm: NgForm) {
    this.baseUrl = this.url + '/api/EaseRent/addAllForm';
    if (careTakerForm.invalid) {
      this.isSubmitted = true;
      return;
    }

    this.isSubmitted = false;
    this.commonService.showLoading();
    const allForm = {
      contactUsBean: {
        email: null,
        message: null,
        name: null,
        phone: null,
        subject: null
      },
      commercialrentBean: {
        contactno: null,
        emailId: null,
        location: null,
        name: null,
        price: null,
        requirements: null,
        sqfeet: null,
        whatsapp: null
      },
      homeCareBean:{
        contactno: null,
        emailId: null,
        location: null,
        name: null,
        propertyDetails: null,
        service: null,
        whatsapp: null
      },
      postRequirementBean:{
        budget: null,
        contactNumber: null,
        emailId: null,
        location: null,
        name: null,
        requirements: null,
        whatsappNumber: null
      },
      careTakerBean: this.careTakerModel,
      serviceVillaBean: {
        bhk: null,
        contactno: null,
        days: null,
        emailId: null,
        fromdate: null,
        location: null,
        name: null,
        persons: null,
        price: null,
        requirements: null,
        todate: null,
        whatsapp: null
      },
      submitPropertyBean: {
        advanceAmt: null,
        bhk: null,
        commercial: null,
        contactNumber: null,
        description: null,
        emailId: null,
        location: null,
        maintenance: null,
        owner: null,
        propertyName: null,
        propertyType: null,
        rent: null,
        residential: null,
        whatsapp: null
      }
    }

    this.commonService.post(this.baseUrl, allForm).subscribe((res) => {
      if (res.Status === '200') {
        this.toastr.success("Success", "Thanks for your inquiry. Someone from our team will reach out to you soon !!")
      } else if (res.Status === '400') {
        this.toastr.error('Error', res.message)
      }
      careTakerForm.reset();
      this.careTakerModel = {
        propertyType:'',
        bhk:''
      }
      this.commonService.hideLoading();
    });
  }
}
