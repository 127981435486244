import { Component, Input, OnInit } from '@angular/core';
import { Country, State, City } from 'country-state-city';

import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';

@Component({
  selector: 'search-properties',
  templateUrl: './searchproperties.component.html',
  styleUrls: ['./searchproperties.component.scss']
})
export class SearchPropertiesComponent implements OnInit {
  countries: any = [];
  cities: any[] = [];
  countryCode: string;
  country: any = [];
  codeId: any;
  partnerId: any;
  angularPath: any;
  url: any;
  hostname: any;
  hostUrl: string;
  rentFromPrice: any = 0;
  rentToPrice: any = 0;
  saleFromPrice: any = 0;
  saleToPrice: any = 0;
  citySale: string = "";
  cityRent: string = "";

  // --Select property type--
  rentPriceRange: any = [
    "0 -- 5K",
    "5K -- 10K",
    "10K -- 15K",
    "15K -- 20K",
    "20K -- 30K",
    "30K -- 40K",
    "40K -- 50K",
    "50K -- 60K",
    "60K -- 70K",
    "70K -- 80K",
    "80K -- 90K",
    "90K -- 1Lakh",
  ];

  salePriceRange: any = [
    "0 -- 5Lakh",
    "5Lakh -- 10Lakh",
    "10Lakh -- 20Lakh",
    "20Lakh -- 30Lakh",
    "30Lakh -- 40Lakh",
    "40Lakh -- 50Lakh",
    "50Lakh -- 75Lakh",
    "75Lakh -- 1Crore",
    "1Crore -- 3Crore",
    "3Crore -- 5Crore",
  ];

  propertiesType: any = [
    "Apartment/Flat",
    "Builder Multi Unit",
    "Commercial Property",
    "Co-working Space",
    "Hospitality",
    "House/Villa",
    "Office",
    "Paying Guest",
    "Plot & Farmhouse",
    "Retail",
    "Showroom",
    "Storage",
    "Studio",
    "Warehouses"
  ];

  bhkList: any = [
    "1BHK",
    "2BHK",
    "3BHK",
    "4BHK",
    "1 RK/Studio"
  ];

  default: any;


  saleForm: FormGroup;
  rentForm: FormGroup;
  type: any;
  propertyType: any;
  fromPrice: any;
  toPrice: any;
  city: any;
  bhk: any;
  selectedType: any;
  selectedPrice: any;
  selectedBhk: any;
  isSaleSubmitted = false;
  isRentSubmitted = false;
  state: any;
  cityName: any;
  stateCode: any;
  cittyList: any = [];


  constructor(private location: Location, private fb: FormBuilder) {
    this.craeteSaleForm();
    this.createRentForm();
    this.cityName = "Kochi";
  }


  ngOnInit() {
    this.getSearchProperties();
    this.setDeafultSaleValue();
    this.setDeafultRentValue();
  }

  craeteSaleForm() {
    this.saleForm = this.fb.group({
      city: ['', Validators.required],
      propertyType: '',
      price: '',
      bhk: ''
    })
  }

  createRentForm() {
    this.rentForm = this.fb.group({
      city: ['', Validators.required],
      propertyType: '',
      price: '',
      bhk: ''
    })
  }

  getDefaultCountry() {
    this.country = Country.getAllCountries();
    this.country = this.country.filter((item) => {
      if (item.isoCode === "IN") {
        return item;
      }
    });
    this.countryCode = this.country[0].isoCode;
  }

  getSearchProperties() {
    this.angularPath = this.location.path();
    this.hostname = window.location.hostname;
    this.hostUrl = window.location.href;
    this.hostUrl = this.hostUrl.substring(0, this.hostUrl.length - 1);
    if (this.hostname === "localhost") {
      this.url = "http://localhost:4200";
    } else if (this.hostname === "www.easerent.com") {
      this.url = "https://app.easerent.com";
    } else if (this.hostname === "stag.easerent.com") {
      this.url = "https://stagapp.easerent.com";
    } else if (this.hostname === "rentkochi.com") {
      this.url = "https://rentkochi.easerent.com"
    }
    else {
      this.url = "https://" + this.hostname;
    }
  }

  onRentPriceChange(value: string) {
    var range = value.split(" ");
    this.rentFromPrice = range[0];
    this.rentToPrice = range[2];
  }
  onSalePriceChange(value: string) {
    var range = value.split(" ");
    this.saleFromPrice = range[0];
    this.saleToPrice = range[2];
  }

  //City Autocomplete
  keyword = 'name';
  saleCityData = [];
  rentCityData = [];
  selectSaleCity(item) {
    this.citySale = item.name;
  }

  selectRentCity(item) {
    this.cityRent = item.name;
  }
  onChangeSearchSale(val: string) {
    this.saleCityData = this.cities;
  }
  onChangeSearchRent(val: string) {
    this.rentCityData = this.cities;
  }
  onClearedSale(e) {
    this.citySale = "";
  }
  onClearedRent(e) {
    this.cityRent = "";
  }
  onFocusedSale(e) {
    // do something when input is focused
  }
  onFocusedRent(e) {
    // do something when input is focused
  }

  setDeafultSaleValue() {
    this.saleForm.patchValue({
      propertyType: this.propertiesType[1].value,
      price: this.salePriceRange[1].value,
      bhk: this.bhkList[1].value
    })
  }

  setDeafultRentValue() {
    this.rentForm.patchValue({
      propertyType: this.propertiesType[1].value,
      price: this.salePriceRange[1].value,
      bhk: this.bhkList[1].value
    })
  }

  findSaleSearchProperties() {
    if (this.saleForm.controls.city.value === "") {
      this.isSaleSubmitted = true;
      return;
    }

    let baseUrl = null;
    let currentPrice = null;
    let fromPrice = null;
    let toPrice = null;
    if (this.saleForm.value.price !== undefined) {
      currentPrice = this.saleForm.value.price.split(" ");

      if (currentPrice.length === 1) {
        fromPrice = currentPrice[0];
        toPrice = currentPrice[0];
      } else {
        fromPrice = currentPrice[0];
        toPrice = currentPrice[2];
      }
    } else {
      fromPrice = "";
      toPrice = "";
    }

    if (this.saleForm.value.propertyType === undefined || this.saleForm.value.propertyType === null) {
      this.propertyType = "";
    } else {
      this.propertyType = this.saleForm.value.propertyType;
    }
    if (this.saleForm.value.bhk === undefined || this.saleForm.value.bhk === null) {
      this.bhk = "";
    } else {
      this.bhk = this.saleForm.value.bhk;
    }


    this.type = "Sale"
    this.fromPrice = fromPrice;
    this.toPrice = toPrice;
    this.city = this.saleForm.value.city.name;
    this.bhk = this.saleForm.value.bhk;

    baseUrl = this.url + '/property/search/propertyWebsite?type=' + this.type +
      '&propertyType=' + this.propertyType + '&fromPrice=' + this.fromPrice + '&toPrice=' + this.toPrice +
      '&city=' + this.city + '&bhk=' + this.bhk;

    window.open(baseUrl);
  }

  findRentSearchProperties() {
    let baseUrl = null;
    let currentPrice = null;
    let fromPrice = null;
    let toPrice = null;
    if (this.rentForm.value.price !== undefined) {
      currentPrice = this.rentForm.value.price.split(" ");

      if (currentPrice.length === 1) {
        fromPrice = currentPrice[0];
        toPrice = currentPrice[0];
      } else {
        fromPrice = currentPrice[0];
        toPrice = currentPrice[2];
      }
    } else {
      fromPrice = "";
      toPrice = "";
    }

    if (this.rentForm.value.propertyType === undefined || this.rentForm.value.propertyType === null) {
      this.propertyType = "";
    } else {
      this.propertyType = this.rentForm.value.propertyType;
    }
    if (this.rentForm.value.bhk === undefined || this.rentForm.value.bhk === null) {
      this.bhk = "";
    } else {
      this.bhk = this.rentForm.value.bhk;
    }

    this.type = "Rent"
    this.fromPrice = fromPrice;
    this.toPrice = toPrice;
    this.city = this.cityName
    this.bhk = this.rentForm.value.bhk;

    baseUrl = this.url + '/property/search/propertyWebsite?type=' + this.type +
      '&propertyType=' + this.propertyType + '&fromPrice=' + this.fromPrice + '&toPrice=' + this.toPrice +
      '&city=' + this.city + '&bhk=' + this.bhk;

    window.open(baseUrl);

  }
}
