<section class="overview-area ptb-100">
    <div class="section-title">
        <h2>Post Requirements</h2>
        <div class="bar"></div>
        <p><strong>Hi Tenants,

                Are YOU looking For A BUDGET FRIENDLY RENTED HOME/FLAT/VILLA in KOCHI?

                Rentkochi.com WILL HELP YOU to Finilize best Rental Deal in Kochi

                Post Your Requirements,

                We will support in finalize Your Rental Home</strong></p>
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="overview-image text-center">
                    <img src="assets/img/postreq.png" alt="image" style="width: 90%;">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <form id="postRequirementForm" #postRequirementForm="ngForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="name" type="text" id="name" placeholder="Your Name*"
                                        class="form-control" pattern="^[A-Za-z\s]*$" #name="ngModel"
                                        [(ngModel)]="postRequirementModel.name" maxlength="100" required>
                                    <div *ngIf="!name?.valid && (name?.dirty || name?.touched || isSubmitted)" class="text-danger">
                                        <div *ngIf="name.errors.required">
                                            Name is required
                                        </div>
                                        <div *ngIf="name.errors.pattern">
                                            Only characters are allowed
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="contactno" type="text" id="phone" class="form-control"
                                        placeholder="Contact No*" pattern="^[0-9]*$" #contactNumber="ngModel"
                                        [(ngModel)]="postRequirementModel.contactNumber" maxlength="10" required>
                                    <div *ngIf="!contactNumber?.valid && (contactNumber?.dirty || contactNumber?.touched || isSubmitted)"
                                        class="text-danger">
                                        <div *ngIf="contactNumber.errors.required">
                                            Contact Number is required
                                        </div>
                                        <div *ngIf="contactNumber.errors.pattern">
                                            Only Numbers are allowed
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="whatsapp" type="text" id="whatsapp" placeholder="WhatsApp No*"
                                        class="form-control" pattern="^[0-9]*$" #whatsappNumber="ngModel"
                                        [(ngModel)]="postRequirementModel.whatsappNumber" maxlength="10" required>
                                    <div *ngIf="!whatsappNumber?.valid && (whatsappNumber?.dirty || whatsappNumber?.touched || isSubmitted)"
                                        class="text-danger">
                                        <div *ngIf="whatsappNumber.errors.required">
                                            Whatsapp Number is required
                                        </div>
                                        <div *ngIf="whatsappNumber.errors.pattern">
                                            Only Numbers are allowed
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="email" type="email" id="email" class="form-control"
                                        placeholder="Your Email" #emailId="ngModel"
                                        [(ngModel)]="postRequirementModel.emailId" email="true">
                                    <div *ngIf="emailId.errors?.email && (emailId.dirty || emailId.touched)"
                                        class="text-danger">
                                        Email address is not valid.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="location" type="text" id="location" placeholder="Prefered Location*"
                                        class="form-control" #location="ngModel"
                                        [(ngModel)]="postRequirementModel.location" required>

                                    <div *ngIf="location.errors?.required && (location.touched || isSubmitted)"
                                        class="text-danger">
                                        Location is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <select name="budget" id="budget" class="form-control" #budget="ngModel"
                                        [(ngModel)]="postRequirementModel.budget"
                                        (change)="onRentPriceChange(budget.value)" required>
                                        <option value="" selected disabled hidden>--Select Budget--</option>
                                        <option [ngValue]="price" *ngFor="let price of priceRange">
                                            {{price}} </option>

                                        <input [value]="fromPrice" type="text" name="fromPrice" id="fromPrice"
                                            class="form-control" hidden>
                                        <input [value]="toPrice" type="text" name="toPrice" id="toPrice"
                                            class="form-control" hidden>
                                    </select>

                                    <div *ngIf="budget.errors?.required && (budget.touched || isSubmitted)"
                                        class="text-danger">
                                        Budget is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="requirements" rows="6" id="requirements"
                                        placeholder="Enter Requirements*" class="form-control" #requirements="ngModel"
                                        [(ngModel)]="postRequirementModel.requirements" required></textarea>

                                    <div *ngIf="requirements.errors?.required && (requirements.touched || isSubmitted)"
                                        class="text-danger">
                                        Requirements is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="send-btn">
                                    <button class="default-btn"
                                        (click)="sendPostRequirement(postRequirementForm)">Submit
                                        <span></span></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>