<div class="container">
    <div class="row align-items-center">
        <div class="col-lg-6 col-md-12">
            <div class="about-image">
                <img src="assets/img/abt.png" alt="About RentKochi">
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="about-content">
                <h5 class="pre-heading">WHO WE ARE </h5>
                <h3>About <span class="highlight">RentKochi</span></h3>
                <div class="bar"></div>
                <p><b>
                        RENTKOCHI.com</b> <strong> is a </strong> <b>property management Services/Personal Rental
                        Services initiative for
                        KOCHI City promoted by WBC (WIN BUSINESS CONSULTING PRIVATE LIMITED)</b> <strong>& The focus
                        area of operation is in </strong> <b>KOCHI CITY.</b>

                </p>
                <p><strong>
                        Our business motive is providing</strong> <b> PROPERTY RENTAL SEARCH SERVICES in KOCHI CITY in
                        Property Renting/Leasing.</b>

                </p>
                <p><b>
                        RENTKOCHI.com manages by team of service experts helps you in FINDING YOUR DREAM RENTAL HOME in
                        KOCHI in Your Budget in Your Preferred Location.
                    </b>
                </p>

                <p><strong>Our USP is LISTEN & DELIVER –
                        WE Listen: <b>(WHATSAPP: 906 1567 906)</b>
                    </strong>
                </p>
                <p><b>
                        “Your REQUIREMENTS + BUDGET + Preferred
                        Location in Kochi “
                    </b>
                </p>

                <p> <strong>
                        Then,
                    </strong>
                </p>
                <p><b>
                        WE will SUPPORT YOU Till Key HANDOVER</b> <strong> & after
                        Key handover through HOME CARE SERVICES
                        like HOME Cleaning/Electrician/Plumbing/Carpentry & arranging cost
                        effective electronic appliances for
                        HOME.
                    </strong>
                </p>
            </div>
        </div>
    </div>
</div>