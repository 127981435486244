import { Component, Input, OnInit } from '@angular/core';
import { NgForm  } from '@angular/forms';
import { Location } from '@angular/common';
import { CommomService } from 'src/app/commom.service';
import { ToastrService } from 'ngx-toastr';




@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],

})
export class ContactComponent implements OnInit {

  @Input() close: () => void;
  contactUsModel: any = {};
  isSubmitted = false;
  
  angularPath: any;
  hostname: string;
  hostUrl: string;
  url: string;
  baseUrl:any;
  port: any;
 
  constructor(
    private location: Location,
    public commonService: CommomService,
    private toastr: ToastrService
    ) {}

  ngOnInit() {
    this.getBaseUrl();
  }

  getBaseUrl(){
    this.angularPath = this.location.path();
    this.hostname = window.location.hostname;
    this.port= window.location.port;
    this.hostUrl = window.location.href;
    this.hostUrl = this.hostUrl.substring(0,this.hostUrl.length - 1);
    if(this.hostname === "localhost") {
      this.url = "http://localhost:8000";
    }else if(this.hostname === "www.easerent.com") {
      this.url = "https://app.easerent.com";
    }else if(this.hostname === "stag.easerent.com") {
      this.url = "https://stagapp.easerent.com";
    }else if(this.hostname === "rentkochi.com") {
      this.url = "https://rentkochi.easerent.com"
    }else {
      this.url = "https://" + this.hostname;
    }
  }

  SendContactUsDetail(contactForm: NgForm){ 
    this.baseUrl = this.url + '/api/EaseRent/addAllForm';
    if (contactForm.invalid) {
      this.isSubmitted = true;
      return;
    }

    this.isSubmitted = false;
    this.commonService.showLoading();
    const allForm = {
      contactUsBean: this.contactUsModel,
      commercialrentBean: {
        contactno: null,
        emailId: null,
        location: null,
        name: null,
        price: null,
        requirements: null,
        sqfeet: null,
        whatsapp: null
      },
      homeCareBean:{
        contactno: null,
        emailId: null,
        location: null,
        name: null,
        propertyDetails: null,
        service: null,
        whatsapp: null
      },
      postRequirementBean:{
        budget: null,
        contactNumber: null,
        emailId: null,
        location: null,
        name: null,
        requirements: null,
        whatsappNumber: null
      },
      careTakerBean:{
        bhk: null,
        contactno: null,
        description: null,
        emailId: null,
        name: null,
        propertyType: null,
        propertyname: null,
        whatsapp: null
      },
      serviceVillaBean: {
        bhk: null,
        contactno: null,
        days: null,
        emailId: null,
        fromdate: null,
        location: null,
        name: null,
        persons: null,
        price: null,
        requirements: null,
        todate: null,
        whatsapp: null
      },
      submitPropertyBean: {
        advanceAmt: null,
        bhk: null,
        commercial: null,
        contactNumber: null,
        description: null,
        emailId: null,
        location: null,
        maintenance: null,
        owner: null,
        propertyName: null,
        propertyType: null,
        rent: null,
        residential: null,
        whatsapp: null
      }
    }
    this.commonService.post(this.baseUrl, allForm).subscribe((res) => {
      if (!!res) {
      //  this.toastr.success("Success", res.message)
        this.toastr.success("Success", "Thanks for your inquiry. Someone from our team will reach out to you soon !!")
      } 
      contactForm.reset();
      this.commonService.hideLoading();
      if (this.close) {
        this.close();
      }
    });
  }

  closed() {
    this.close();
  }
}
