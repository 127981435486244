<home-slider></home-slider>

<section>
    <search-properties></search-properties>
</section>

<!-- <section  class="main-banner ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-5">
                <div class="banner-content">
                    <h5 class="pre-heading">ALL-IN-ONE PROPERTY MANAGEMENT SOFTWARE</h5>
                    <h1>Manage all your <span class="highlight">properties, leads, tenants</span> from your fingertips</h1>
                    <p>
                        What you get -<br> Real estate CRM - manage all your leads from single place,  Property management - all documents on cloud, End to end rental management, Fully automated move-in/move-out process, Service request and vendor management.
                    </p>
                    <div class="banner-holder">
                        <a href="https://play.google.com/store/apps/details?id=com.easerent" target="_blank"><img src="assets/img/store/1.png" alt="image"></a>
                        <a routerLink="/comingsoon"><img src="assets/img/store/2.png" alt="image"></a>
                    </div>
                </div>
            </div>

            <div class="feature-carousel-area col-sm-12 col-md-12 col-lg-7">
                <app-feature-carousel></app-feature-carousel>
            </div>
        </div>
    </div>
</section> -->

<section id="about" class="about-area ptb-70">
    <app-about *ngIf="showWorks === false"></app-about>

    <!-- <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div> -->
</section>
<section id="howitworks" class="about-area ptb-70">
    <app-how-it-works *ngIf="showWorks === true"></app-how-it-works>

    <!-- <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div> -->
</section>
<!-- <section class="marketing-area pt-70">
    <app-marketing></app-marketing>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section> -->
<!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style="margin-top: -2px;">
    <path fill="#357c52" fill-opacity="1" d="M0,256L60,234.7C120,213,240,171,360,154.7C480,139,600,149,720,170.7C840,192,960,224,1080,213.3C1200,203,1320,149,1380,122.7L1440,96L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
</svg> -->

<section id="services" class="features-area">
    <app-features></app-features>

    <!-- <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div> -->
</section>

<!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style="margin-bottom: -2px;">
    <path fill="#357c52" fill-opacity="1" d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
</svg> -->



<!-- <section class="about-area ptb-100">
    <app-about-two></app-about-two>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section> -->

<!-- <section class="testimonial-area ptb-70">
    <app-testimonials></app-testimonials>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section> -->

<section id="contact" class="contact-area ptb-100">
    <app-contact></app-contact>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section>

<!-- <section class="pricing-area ptb-70">
    <app-pricing></app-pricing>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section> -->


<!-- <section class="fun-facts-area pt-100 pb-70">
    <app-funfacts></app-funfacts>
</section> -->


<!-- <section id="features" class="features-area pb-70">
    <app-features></app-features>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section> -->

<!--<app-app-screenshots></app-app-screenshots> -->



<!-- <section class="overview-area ptb-100">
    <app-overview></app-overview>
</section> -->



<!-- <section id="faq" class="faq-area ptb-100">
    <app-faq></app-faq>

    <div class="default-shape">
        <div class="shape-1"><img src="assets/img/shape/1.png" alt="image"></div>
        <div class="shape-2 rotateme"><img src="assets/img/shape/2.png" alt="image"></div>
        <div class="shape-3"><img src="assets/img/shape/3.svg" alt="image"></div>
        <div class="shape-4"><img src="assets/img/shape/4.svg" alt="image"></div>
        <div class="shape-5"><img src="assets/img/shape/5.png" alt="image"></div>
    </div>
</section> -->

<!-- <section class="team-area pt-100 pb-70">
    <app-team></app-team>
</section> -->

<!-- <app-app-download></app-app-download> -->

<!-- <section id="blog" class="blog-area pt-100 pb-70">
    <app-blog></app-blog>
</section> -->



<!-- <app-subscribe></app-subscribe> -->