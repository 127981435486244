import { Component } from '@angular/core';

@Component({
  selector: 'home-slider',
  templateUrl: './homeslider.component.html',
  styleUrls: ['./homeslider.component.scss']
})
export class HomeSliderComponent {

  images = ['assets/img/slider1.png', 
            'assets/img/slider2.png',
            'assets/img/silder3.png',
            'assets/img/slider4.png', 
            'assets/img/slider5.png',
            'assets/img/slider6.png'
          ];

}
