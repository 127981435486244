import { NgForm } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommomService } from 'src/app/commom.service';

@Component({
  selector: 'app-commercialrent',
  templateUrl: './commercialrent.component.html',
  styleUrls: ['./commercialrent.component.scss']
})
export class CommercialRentComponent implements OnInit {

  commercialRentModel:any = {};
  isSubmitted = false;
  budget:any= [
    "0 -- 5K",
    "5K -- 10K",
    "10K -- 15K",
    "15K -- 20K",
    "20K -- 30K",
    "30K -- 40K",
    "40K -- 50K",
    ">50K"
  ];


  fromPrice: any;
  toPrice: any;
  hostUrl: string;
  hostname: string;
  url: string;
  baseUrl: string;

  constructor(
    public commonService: CommomService,
    private toastr: ToastrService,
  ) {
    this.commercialRentModel = {
      price:''
    }
   }

  ngOnInit(): void {
    this.getBaseUrl();
  }

  getBaseUrl(){
    this.hostname = window.location.hostname;
    if(this.hostname === "localhost") {
      this.url = "http://localhost:8000";
    }else if(this.hostname === "rentkochi.com") {
      this.url = "https://rentkochi.easerent.com"
    }
    else {
      this.url = "https://" + this.hostname;
    }
  }

  onRentPriceChange(value:any) {
    var range = value.split(" ");
    this.fromPrice = range[0];
    this.toPrice = range[2];
  }

  sendCommercialRent(commercialRentForm: NgForm) {
    this.baseUrl = this.url + '/api/EaseRent/addAllForm';
    if (commercialRentForm.invalid) {
      this.isSubmitted = true;
      return;
    }

    this.isSubmitted = false;
    this.commonService.showLoading();
    const allForm = {
      contactUsBean: {
        email: null,
        message: null,
        name: null,
        phone: null,
        subject: null
      },
      commercialrentBean: this.commercialRentModel,
      homeCareBean:{
        contactno: null,
        emailId: null,
        location: null,
        name: null,
        propertyDetails: null,
        service: null,
        whatsapp: null
      },
      postRequirementBean:{
        budget: null,
        contactNumber: null,
        emailId: null,
        location: null,
        name: null,
        requirements: null,
        whatsappNumber: null
      },
      careTakerBean:{
        bhk: null,
        contactno: null,
        description: null,
        emailId: null,
        name: null,
        propertyType: null,
        propertyname: null,
        whatsapp: null
      },
      serviceVillaBean: {
        bhk: null,
        contactno: null,
        days: null,
        emailId: null,
        fromdate: null,
        location: null,
        name: null,
        persons: null,
        price: null,
        requirements: null,
        todate: null,
        whatsapp: null
      },
      submitPropertyBean: {
        advanceAmt: null,
        bhk: null,
        commercial: null,
        contactNumber: null,
        description: null,
        emailId: null,
        location: null,
        maintenance: null,
        owner: null,
        propertyName: null,
        propertyType: null,
        rent: null,
        residential: null,
        whatsapp: null
      }
    }

    this.commonService.post(this.baseUrl, allForm).subscribe((res) => {
      if (res.Status === '200') {
        this.toastr.success("Success", "Thanks for your inquiry. Someone from our team will reach out to you soon !!")
      } else if (res.Status === '400') {
        this.toastr.error('Error', res.message)
      }
      commercialRentForm.reset();
      this.commercialRentModel = {
        price:''
      }
      this.commonService.hideLoading();
    });
  }
}
