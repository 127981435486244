<section class="overview-area ptb-100">
    <div class="section-title">
        <h2>Submit Property</h2>
        <div class="bar"></div>
        <strong>Owners can submit property for Rent to rentkochi.com website as AVAILABLE for Rent</strong>
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <form id="submitPropertyForm" #submitPropertyForm="ngForm">
                        <div class="row">
                            <div class="col-lg-3 col-md-3">
                                <div class="form-group pt-3">
                                    <input type="radio" id="html" name="fav_language" value="residential"
                                        #residential="ngModel" [(ngModel)]="submitPropertyModel.residential" checked>
                                    <label for="residential">&nbsp;Residential</label>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3 pt-3">
                                <div class="form-group">
                                    <input type="radio" id="css" name="fav_language" value="commercial"
                                        #commercial="ngModel" [(ngModel)]="submitPropertyModel.commercial">
                                    <label for="commercial">&nbsp;Commercial</label>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <select name="ptype" id="ptype" class="form-control" #propertyType="ngModel"
                                        [(ngModel)]="submitPropertyModel.propertyType" required>
                                        <option value="" selected disabled hidden>--Select property type--</option>
                                        <option [ngValue]="type" *ngFor="let type of propertiesType">{{type}}</option>
                                    </select>

                                    <div *ngIf="propertyType.errors?.required && (propertyType.touched || isSubmitted)"
                                        class="text-danger">
                                        Property type is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="email" type="email" id="email" class="form-control"
                                        placeholder="Your Email" #emailId="ngModel"
                                        [(ngModel)]="submitPropertyModel.emailId" email="true">
                                    <div *ngIf="emailId.errors?.email && (emailId.dirty || emailId.touched)"
                                        class="text-danger">
                                        Email address is not valid.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="contactno" type="text" id="phone" class="form-control"
                                        placeholder="Contact No*" pattern="^[0-9]*$" #contactNumber="ngModel"
                                        [(ngModel)]="submitPropertyModel.contactNumber" maxlength="10" required>
                                    <div *ngIf="!contactNumber?.valid && (contactNumber?.dirty || contactNumber?.touched)"
                                        class="text-danger">
                                        <div *ngIf="contactNumber.errors.required">
                                            Contact Number is required
                                        </div>
                                        <div *ngIf="contactNumber.errors.pattern">
                                            Only Numbers are allowed
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="whatsapp" type="text" id="whatsapp" placeholder="WhatsApp No*"
                                        class="form-control" pattern="^[0-9]*$" #whatsapp="ngModel"
                                        [(ngModel)]="submitPropertyModel.whatsapp" maxlength="10" required>
                                    <div *ngIf="!whatsapp?.valid && (whatsapp?.dirty || whatsapp?.touched)"
                                        class="text-danger">
                                        <div *ngIf="whatsapp.errors.required">
                                            Whatsapp Number is required
                                        </div>
                                        <div *ngIf="whatsapp.errors.pattern">
                                            Only Numbers are allowed
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="rent" type="text" id="rent" placeholder="Rent Expected*"
                                        class="form-control" #rent="ngModel" [(ngModel)]="submitPropertyModel.rent"
                                        required>

                                    <div *ngIf="rent.errors?.required && (rent.touched || isSubmitted)"
                                        class="text-danger">
                                        Rent Expected is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="maintenance" type="text" id="maintenance"
                                        placeholder="Monthly Maintenance" class="form-control" #maintenance="ngModel"
                                        [(ngModel)]="submitPropertyModel.maintenance" required>

                                    <div *ngIf="maintenance.errors?.required && (maintenance.touched || isSubmitted)"
                                        class="text-danger">
                                        Monthly Maintenance is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="advanceamt" type="text" id="advanceamt"
                                        placeholder="Advance / Security Deposit " class="form-control"
                                        #advanceAmt="ngModel" [(ngModel)]="submitPropertyModel.advanceAmt" required>

                                    <div *ngIf="advanceAmt.errors?.required && (advanceAmt.touched || isSubmitted)"
                                        class="text-danger">
                                        Advance / Security Deposit is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="location" type="text" id="location" placeholder="Location*"
                                        class="form-control" #location="ngModel"
                                        [(ngModel)]="submitPropertyModel.location" required>

                                    <div *ngIf="location.errors?.required && (location.touched || isSubmitted)"
                                        class="text-danger">
                                        Location is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <select name="bhk" id="bhk" class="form-control" #bhk="ngModel"
                                        [(ngModel)]="submitPropertyModel.bhk" required>
                                        <option value="" selected disabled>--Select BHK--</option>
                                        <option [ngValue]="bhk" *ngFor="let bhk of bhkList">{{bhk}}</option>
                                    </select>

                                    <div *ngIf="bhk.errors?.required && (bhk.touched || isSubmitted)"
                                        class="text-danger">
                                        BHK is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="owner" type="text" id="owner" placeholder="Owner Name*"
                                        class="form-control" pattern="^[a-zA-Z]+$" #owner="ngModel"
                                        [(ngModel)]="submitPropertyModel.owner" maxlength="100" required>

                                    <div *ngIf="!owner?.valid && (owner?.dirty || owner?.touched)" class="text-danger">
                                        <div *ngIf="owner.errors.required">
                                            Owner Name is required
                                        </div>
                                        <div *ngIf="owner.errors.pattern">
                                            Only characters are allowed
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="propertyname" type="text" id="propertyname"
                                        placeholder="Property Name*" class="form-control" #propertyName="ngModel"
                                        [(ngModel)]="submitPropertyModel.propertyName" required>

                                    <div *ngIf="propertyName.errors?.required && (propertyName.touched || isSubmitted)"
                                        class="text-danger">
                                        Property Name is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="desc" rows="6" id="desc" placeholder="Enter Property Description*"
                                        class="form-control" #description="ngModel"
                                        [(ngModel)]="submitPropertyModel.description" required></textarea>

                                    <div *ngIf="description.errors?.required && (description.touched || isSubmitted)"
                                        class="text-danger">
                                        Description is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="send-btn">
                                    <button class="default-btn" (click)="submitProperty(submitPropertyForm)">Submit
                                        <span></span></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="overview-image text-center">
                    <img src="assets/img/submitproperty.png" alt="image" style="width: 80%;">
                </div>
            </div>
        </div>
    </div>
</section>