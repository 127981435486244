import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

  showWorks:boolean = false
  child: any;
  constructor() { 
    window.addEventListener('click', event => {
      
      if(event.target['hash'] === "#howitworks") {
        this.showWorks = true;
      }else {
        this.showWorks = false;
      }
    });
    
  }

  ngOnInit(): void {
  }

}
