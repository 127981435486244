<section class="overview-area ptb-100">
    <div class="section-title">
        <h2>Caretaker Services for NRIs</h2>
        <div class="bar"></div>
        <p><strong>Do you have a query?

                or want to get in touch with us?

                Give us a call or email us. You will definitely hear back from us.</strong></p>
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <form id="careTakerForm" #careTakerForm="ngForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="name" type="text" id="name" placeholder="Your Name*"
                                        class="form-control" #name="ngModel" [(ngModel)]="careTakerModel.name"
                                        pattern="^[A-Za-z\s]*$" required>
                                    <div *ngIf="!name?.valid && (name?.dirty || name?.touched || isSubmitted)" class="text-danger">
                                        <div *ngIf="name.errors.required">
                                            Name is required
                                        </div>
                                        <div *ngIf="name.errors.pattern">
                                            Only characters are allowed
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="contactno" type="text" id="phone" class="form-control"
                                        placeholder="Contact No*" pattern="^[0-9]*$" #contactno="ngModel"
                                        [(ngModel)]="careTakerModel.contactno" maxlength="10" required>
                                    <div *ngIf="!contactno?.valid && (contactno?.dirty || contactno?.touched || isSubmitted)"
                                        class="text-danger">
                                        <div *ngIf="contactno.errors.required">
                                            Contact Number is required
                                        </div>
                                        <div *ngIf="contactno.errors.pattern">
                                            Only Numbers are allowed
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="whatsapp" type="text" id="whatsapp" placeholder="WhatsApp No*"
                                        class="form-control" pattern="^[0-9]*$" #whatsapp="ngModel"
                                        [(ngModel)]="careTakerModel.whatsapp" maxlength="10" required>
                                    <div *ngIf="!whatsapp?.valid && (whatsapp?.dirty || whatsapp?.touched || isSubmitted)"
                                        class="text-danger">
                                        <div *ngIf="whatsapp.errors.required">
                                            Whatsapp Number is required
                                        </div>
                                        <div *ngIf="whatsapp.errors.pattern">
                                            Only Numbers are allowed
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="email" type="email" id="email" class="form-control"
                                        placeholder="Your Email" #emailId="ngModel" [(ngModel)]="careTakerModel.emailId"
                                        email="true">
                                    <div *ngIf="emailId.errors?.email && (emailId.dirty || emailId.touched)"
                                        class="text-danger">
                                        Email address is not valid.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <select name="type" id="type" class="form-control" #propertyType="ngModel"
                                        [(ngModel)]="careTakerModel.propertyType" required>
                                        <option value="" selected hidden>--Select Property Type--</option>
                                        <option [ngValue]="type" *ngFor="let type of propertiesType">{{type}}</option>
                                    </select>
                                    <div *ngIf="propertyType.errors?.required && (propertyType.touched || isSubmitted)"
                                        class="text-danger">
                                        Property Type is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <select name="bhk" id="bhk" class="form-control" #bhk="ngModel"
                                        [(ngModel)]="careTakerModel.bhk" required>
                                        <option value="" selected hidden>--Select BHK--</option>
                                        <option [ngValue]="bhk" *ngFor="let bhk of bhkList">{{bhk}}</option>
                                    </select>
                                    <div *ngIf="bhk.errors?.required && (bhk.touched || isSubmitted)"
                                        class="text-danger">
                                        BHK is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input name="propertyname" type="text" id="propertyname"
                                        placeholder="Property Name*" class="form-control" #propertyname="ngModel"
                                        [(ngModel)]="careTakerModel.propertyname" required>
                                    <div *ngIf="propertyname.errors?.required && (propertyname.touched || isSubmitted)"
                                        class="text-danger">
                                        Property Name is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="description" rows="6" id="description"
                                        placeholder="Enter description*" class="form-control" #description="ngModel"
                                        [(ngModel)]="careTakerModel.description" required></textarea>
                                    <div *ngIf="description.errors?.required && (description.touched || isSubmitted)"
                                        class="text-danger">
                                        Description is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="send-btn">
                                    <button class="default-btn" (click)="sendCarfeTaker(careTakerForm)">Submit
                                        <span></span></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="overview-image text-center">
                    <img src="assets/img/caretaker.png" alt="image" style="width: 90%;">
                </div>
            </div>
        </div>
    </div>
</section>