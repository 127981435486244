<div class="container"
    style="position: relative; margin-top: 20px; background: white; border-radius: 20px; padding: 20px; box-shadow: 0px 20px 50px -20px #444444;;">

    <div class="row align-items-center">
        <div class="col-lg-12">
            <h3 class="text-center highlight" style="padding-bottom: 20px;">One stop shop for all property rental needs in Kochi</h3>
            <div class="tabs_item">
                <div class="search-property-form">
                    <form id="rentSearchForm" [formGroup]="rentForm">
                        <div class="row">
                            <div class="col-lg-3 col-md-3">
                                <div class="form-group">
                                    <select name="propertyType" id="propertyType" class="form-control"
                                        formControlName="propertyType">
                                        <option [ngValue]="undefined" selected>--Select property type--
                                        </option>
                                        <option [value]="type" *ngFor="let type of propertiesType"> {{type}}
                                        </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3">
                                <div class="form-group">
                                    <select class="form-control" #rentPrice
                                        (change)="onRentPriceChange(rentPrice.value)" formControlName="price">
                                        <option [ngValue]="undefined" selected>--Price Range--</option>
                                        <option [value]="price" *ngFor="let price of rentPriceRange"> {{price}}
                                        </option>
                                    </select>
                                    <input [value]="rentFromPrice" type="text" name="fromPrice" id="fromPrice"
                                        class="form-control" placeholder="Price from" hidden>
                                    <input [value]="rentToPrice" type="text" name="toPrice" id="toPrice"
                                        class="form-control" placeholder="Price from" hidden>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3">
                                <div class="form-group">
                                    <select name="bhk" id="bhk" class="form-control" formControlName="bhk">
                                        <option [ngValue]="undefined" selected>--Select BHK--</option>
                                        <option [value]="bhk" *ngFor="let bhk of bhkList"> {{bhk}} </option>
                                    </select>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-3">
                                <div class="form-group">
                                    <input [value]="cityName" type="text" name="city" id="city" class="form-control"
                                        readonly required>
                                </div>
                            </div>

                            <div class="form-group" style="display: none;">
                                <input type="text" name="type" id="type" class="form-control" value="Rent">
                            </div>

                            <div class="col-lg-12 col-md-12 text-center">
                                <div class="send-btn">
                                    <button (click)="findRentSearchProperties()"
                                        class="default-btn">Search<span></span></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>