<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
        <div class="logo">
            <a routerLink="/">
                <img src="assets/img/rentkochi-logo.png" alt="RentKochi" >
            </a>
        </div>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"
        (click)="toggleCollapsed()">
            <span class="navbar-toggler-icon"></span>
        </button>
    
        <div [ngClass]="{'collapse': collapsed, 'navbar-collapse': true}" id="navbarSupportedContent">
            <ul class="navbar-nav">

                <li class="nav-item"><a href="/" class="nav-link">Home</a></li>
                <!-- <li class="nav-item"><a href="#about" class="nav-link" (click)="toggleCollapsed()">About Us</a></li>
                <li class="nav-item"><a href="#services" class="nav-link" (click)="toggleCollapsed()">Our Services</a></li>
                <li class="nav-item"><a href="#howitworks" class="nav-link" (click)="toggleCollapsed()">How It Works</a></li>
                <li class="nav-item"><a href="#contact" class="nav-link" (click)="toggleCollapsed()">Contact Us</a></li> -->
                <li class="nav-item"><a routerLink="/post-requirement" class="nav-link">Post Requirement</a></li>
                <li class="nav-item"><a routerLink="/submit-property" class="nav-link">Submit Property</a></li>
                <li class="nav-item"><a routerLink="/homecare-services" class="nav-link">HomeCare Services</a></li>
                <li class="nav-item"><a routerLink="/commercial-rent" class="nav-link">Commercial</a></li>
                <li class="nav-item"><a routerLink="/furnished-villa" class="nav-link">Furnished Villa</a></li>
                <li class="nav-item"><a routerLink="/caretaker-services" class="nav-link">Caretaker Services</a></li>

            </ul>

            <div class="others-option">
                <div class="d-flex align-items-center">
                    <div class="option-item">
                        <a href="{{this.url}}/login" class="default-btn">Sign In<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>