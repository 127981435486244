<section class="overview-area ptb-100">
    <div class="section-title">
        <h2>Commercial Rent Requirements</h2>
        <div class="bar"></div>
        <p><strong>Do you have a query?

                or want to get in touch with us?

                Give us a call or email us. You will definitely hear back from us.</strong></p>
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="overview-image text-center">
                    <img src="assets/img/commercialrent.png" alt="image" style="width: 90%;">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <form id="commercialRentForm" #commercialRentForm="ngForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="name" type="text" id="name" placeholder="Your Name*"
                                        class="form-control" pattern="^[A-Za-z\s]*$" #name="ngModel"
                                        [(ngModel)]="commercialRentModel.name" required>
                                    <div *ngIf="!name?.valid && (name?.dirty || name?.touched || isSubmitted)" class="text-danger">
                                        <div *ngIf="name.errors.required">
                                            Name is required
                                        </div>
                                        <div *ngIf="name.errors.pattern">
                                            Only characters are allowed
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="contactno" type="text" id="phone" class="form-control"
                                        placeholder="Contact No*" pattern="^[0-9]*$" #contactno="ngModel"
                                        [(ngModel)]="commercialRentModel.contactno" maxlength="10" required>
                                    <div *ngIf="!contactno?.valid && (contactno?.dirty || contactno?.touched || isSubmitted)"
                                        class="text-danger">
                                        <div *ngIf="contactno.errors.required">
                                            Contact Number is required
                                        </div>
                                        <div *ngIf="contactno.errors.pattern">
                                            Only Numbers are allowed
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="whatsapp" type="text" id="whatsapp" placeholder="WhatsApp No*"
                                        class="form-control" pattern="^[0-9]*$" #whatsapp="ngModel"
                                        [(ngModel)]="commercialRentModel.whatsapp" maxlength="10" required>
                                    <div *ngIf="!whatsapp?.valid && (whatsapp?.dirty || whatsapp?.touched || isSubmitted)"
                                        class="text-danger">
                                        <div *ngIf="whatsapp.errors.required">
                                            Whatsapp Number is required
                                        </div>
                                        <div *ngIf="whatsapp.errors.pattern">
                                            Only Numbers are allowed
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="email" type="email" id="email" class="form-control"
                                        placeholder="Your Email" #emailId="ngModel"
                                        [(ngModel)]="commercialRentModel.emailId" email="true">
                                    <div *ngIf="emailId.errors?.email && (emailId.dirty || emailId.touched)"
                                        class="text-danger">
                                        Email address is not valid.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="location" type="text" id="location" placeholder="Prefered Location*"
                                        class="form-control" #location="ngModel"
                                        [(ngModel)]="commercialRentModel.location" required>
                                    <div *ngIf="location.errors?.required && (location.touched || isSubmitted)"
                                        class="text-danger">
                                        Prefered Location is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="sqfeet" type="text" id="sqfeet" placeholder="Minimum Area (Sq. Ft.)*"
                                        class="form-control" #sqfeet="ngModel" [(ngModel)]="commercialRentModel.sqfeet"
                                        required>
                                    <div *ngIf="sqfeet.errors?.required && (sqfeet.touched || isSubmitted)"
                                        class="text-danger">
                                        Minimum Area is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="budget" id="budget" class="form-control" #price="ngModel"
                                        [(ngModel)]="commercialRentModel.price"
                                        (change)="onRentPriceChange(price.value)" required>
                                        <option value="" selected disabled hidden>--Select Budget--</option>
                                        <option [ngValue]="price" *ngFor="let price of budget">
                                            {{price}} </option>

                                        <input [value]="fromPrice" type="text" name="fromPrice" id="fromPrice"
                                            class="form-control" hidden>
                                        <input [value]="toPrice" type="text" name="toPrice" id="toPrice"
                                            class="form-control" hidden>
                                    </select>
                                    <div *ngIf="price.errors?.required && (price.touched || isSubmitted)"
                                        class="text-danger">
                                        Budget is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="requirements" rows="6" id="requirements"
                                        placeholder="Commercial Requirement details*" class="form-control"
                                        #requirements="ngModel" [(ngModel)]="commercialRentModel.requirements"
                                        required></textarea>
                                    <div *ngIf="requirements.errors?.required && (requirements.touched || isSubmitted)"
                                        class="text-danger">
                                        Commercial Requirement details is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="send-btn">
                                    <button class="default-btn" (click)="sendCommercialRent(commercialRentForm)">Submit
                                        <span></span></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>