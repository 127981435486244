<div class="container">
    <!-- <div class="section-title">
        <h5 class="pre-heading">LANDLORD SOFTWARE FOR EASY RENTAL MANAGEMENT</h5>
        <h2>From <span class="highlight">move-in</span> to <span class="highlight">move-out</span>, manage the rental process with our landlord software.</h2>
        <div class="bar"></div>
        <p></p>
    </div> -->

    <div class="row" style="justify-content:center;">
        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fas fa-eye"></i>
                </div>
                <h3>Vision</h3>
                <p>To be the BEST accessible & Quick rental service provider in kochi by 2025</p>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fas fa-bullseye"></i>
                </div>
                <h3>Mission</h3>
                <p>Listen & Deliver Service as per Client Requirements &
                    Budget and to be a worthy rental service provider in
                    Kochi City</p>
            </div>
        </div>

        <!-- <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fas fa-folder-open"></i>
                </div>
                <h3>Document Management</h3>
                <p>Single place for all property documents on cloud providing full transparency and accessibility to all documents for Owners, Tenants and Property Managers.</p>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fas fa-tools"></i>
                </div>
                <h3>Maintenance Requests</h3>
                <p>Maintain your properties by providing required services from your preferred vendors.</p>
            </div>
        </div>

        <div class="col-lg-4 col-md-6">
            <div class="single-features">
                <div class="icon">
                    <i class="fas fa-chart-line"></i>
                </div>
                <h3>Dashboard</h3>
                <p>View summary of your properties and leads, your upcoming site visits/follow-ups and manage appointments/calendar</p>
            </div>
        </div> -->
    </div>
</div>