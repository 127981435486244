<section class="overview-area ptb-100">
    <div class="section-title">
        <h2>Service Apartments / Furnished Villa</h2>
        <h4>(Daily, Weekly, Monthly)</h4>
        <div class="bar"></div>
        <p><strong>Fully Furnished Service Apartment with Cooking facilities/2BHK,

                FULLY FURNISHED VILLA
                for

                Daily Rent/Weekly Rent
                #vacationRentals</strong></p>
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <form id="furnishedVillaForm" #furnishedVillaForm="ngForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="name" type="text" id="name" placeholder="Your Name*"
                                        class="form-control" pattern="^[A-Za-z\s]*$" #name="ngModel"
                                        [(ngModel)]="furnishedVillaModel.name" required>
                                    <div *ngIf="!name?.valid && (name?.dirty || name?.touched || isSubmitted)" class="text-danger">
                                        <div *ngIf="name.errors.required">
                                            Name is required
                                        </div>
                                        <div *ngIf="name.errors.pattern">
                                            Only characters are allowed
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="contactno" type="text" id="phone" class="form-control"
                                        placeholder="Contact No*" pattern="^[0-9]*$" #contactno="ngModel"
                                        [(ngModel)]="furnishedVillaModel.contactno" maxlength="10" required>
                                    <div *ngIf="!contactno?.valid && (contactno?.dirty || contactno?.touched || isSubmitted)"
                                        class="text-danger">
                                        <div *ngIf="contactno.errors.required">
                                            Contact Number is required
                                        </div>
                                        <div *ngIf="contactno.errors.pattern">
                                            Only Numbers are allowed
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="whatsapp" type="text" id="whatsapp" placeholder="WhatsApp No*"
                                        class="form-control" pattern="^[0-9]*$" #whatsapp="ngModel"
                                        [(ngModel)]="furnishedVillaModel.whatsapp" maxlength="10" required>
                                    <div *ngIf="!whatsapp?.valid && (whatsapp?.dirty || whatsapp?.touched || isSubmitted)"
                                        class="text-danger">
                                        <div *ngIf="whatsapp.errors.required">
                                            Whatsapp Number is required
                                        </div>
                                        <div *ngIf="whatsapp.errors.pattern">
                                            Only Numbers are allowed
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="email" type="email" id="email" class="form-control"
                                        placeholder="Your Email" #emailId="ngModel"
                                        [(ngModel)]="furnishedVillaModel.emailId" email="true">
                                    <div *ngIf="emailId.errors?.email && (emailId.dirty || emailId.touched)"
                                        class="text-danger">
                                        Email address is not valid.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="location" type="text" id="location" placeholder="Prefered Location*"
                                        class="form-control" #location="ngModel"
                                        [(ngModel)]="furnishedVillaModel.location" required>
                                    <div *ngIf="location.errors?.required && (location.touched || isSubmitted)"
                                        class="text-danger">
                                        Location is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="persons" type="text" id="persons" placeholder="No. of persons*"
                                        class="form-control" #persons="ngModel"
                                        [(ngModel)]="furnishedVillaModel.persons" required>
                                    <div *ngIf="persons.errors?.required && (persons.touched || isSubmitted)"
                                        class="text-danger">
                                        No. of persons is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="bhk" type="text" id="bhk" placeholder="BHK & Kitchen"
                                        class="form-control" #bhk="ngModel" [(ngModel)]="furnishedVillaModel.bhk"
                                        required>
                                    <div *ngIf="bhk.errors?.required && (bhk.touched || isSubmitted)"
                                        class="text-danger">
                                        BHK & Kitchen is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="days" type="text" id="days" placeholder="No. of Days*"
                                        class="form-control" #days="ngModel" [(ngModel)]="furnishedVillaModel.days"
                                        required>
                                    <div *ngIf="days.errors?.required && (days.touched || isSubmitted)"
                                        class="text-danger">
                                        No. of Days is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="fromdate" type="date" id="fromdate" placeholder="From Date"
                                        class="form-control" #fromdate="ngModel"
                                        [(ngModel)]="furnishedVillaModel.fromdate" required>
                                    <div *ngIf="fromdate.errors?.required && (fromdate.touched || isSubmitted)"
                                        class="text-danger">
                                        From Date is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="todate" type="date" id="todate" placeholder="To Date"
                                        class="form-control" #todate="ngModel" [(ngModel)]="furnishedVillaModel.todate"
                                        required>
                                    <div *ngIf="todate.errors?.required && (todate.touched || isSubmitted)"
                                        class="text-danger">
                                        To Date is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="budget" id="budget" class="form-control" #price="ngModel"
                                        [(ngModel)]="furnishedVillaModel.price"
                                        (change)="onRentPriceChange(price.value)" required>
                                        <option value="" selected disabled hidden>--Select Budget--</option>
                                        <option [ngValue]="price" *ngFor="let price of budget">
                                            {{price}} </option>

                                        <input [value]="fromPrice" type="text" name="fromPrice" id="fromPrice"
                                            class="form-control" hidden>
                                        <input [value]="toPrice" type="text" name="toPrice" id="toPrice"
                                            class="form-control" hidden>
                                    </select>
                                    <div *ngIf="price.errors?.required && (price.touched || isSubmitted)"
                                        class="text-danger">
                                        Budget is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="requirements" rows="6" id="requirements"
                                        placeholder="Enter Requirements*" class="form-control" #requirements="ngModel"
                                        [(ngModel)]="furnishedVillaModel.requirements" required></textarea>
                                    <div *ngIf="requirements.errors?.required && (requirements.touched || isSubmitted)"
                                        class="text-danger">
                                        Requirements is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="send-btn">
                                    <button class="default-btn" (click)="sendfurnishedVilla(furnishedVillaForm)">Submit
                                        <span></span></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="overview-image text-center">
                    <img src="assets/img/furnishedvilla.png" alt="image" style="width: 80%;">
                </div>
            </div>
        </div>
    </div>
</section>