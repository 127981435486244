<img src="assets/img/silder3.png" alt="HomeCare" style="width: 100%;">
<section class="overview-area pb-100">
    <div class="section-title">
        <h2>HomeCare Services</h2>
        <div class="bar"></div>
        <p><strong>Do you have a query?

                or want to get in touch with us?

                Give us a call or email us. You will definitely hear back from us.</strong></p>
    </div>
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="contact-form">
                    <form id="homeCareForm" #homeCareForm="ngForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="name" type="text" id="name" placeholder="Your Name*"
                                        class="form-control" pattern="^[A-Za-z\s]*$" #name="ngModel" [(ngModel)]="homeCareModel.name" required>
                                    <div *ngIf="!name?.valid && (name?.dirty || name?.touched || isSubmitted)" class="text-danger">
                                        <div *ngIf="name.errors.required">
                                            Name is required
                                        </div>
                                        <div *ngIf="name.errors.pattern">
                                            Only characters are allowed
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="contactno" type="text" id="phone" class="form-control"
                                        placeholder="Contact No*" pattern="^[0-9]*$" #contactno="ngModel"
                                        [(ngModel)]="homeCareModel.contactno" maxlength="10" required>
                                    <div *ngIf="!contactno?.valid && (contactno?.dirty || contactno?.touched || isSubmitted)"
                                        class="text-danger">
                                        <div *ngIf="contactno.errors.required">
                                            Contact Number is required
                                        </div>
                                        <div *ngIf="contactno.errors.pattern">
                                            Only Numbers are allowed
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="whatsapp" type="text" id="whatsapp" placeholder="WhatsApp No*"
                                        class="form-control" pattern="^[0-9]*$" #whatsapp="ngModel" [(ngModel)]="homeCareModel.whatsapp"
                                        maxlength="10" required>
                                    <div *ngIf="!whatsapp?.valid && (whatsapp?.dirty || whatsapp?.touched || isSubmitted)"
                                        class="text-danger">
                                        <div *ngIf="whatsapp.errors.required">
                                            Whatsapp Number is required
                                        </div>
                                        <div *ngIf="whatsapp.errors.pattern">
                                            Only Numbers are allowed
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="email" type="email" id="email" class="form-control"
                                        placeholder="Your Email" #emailId="ngModel" [(ngModel)]="homeCareModel.emailId"
                                        email="true">
                                    <div *ngIf="emailId.errors?.email && (emailId.dirty || emailId.touched)"
                                        class="text-danger">
                                        Email address is not valid.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <div class="form-group">
                                        <select name="service" id="service" class="form-control" #service="ngModel"
                                            [(ngModel)]="homeCareModel.service" required>
                                            <option value="" selected disabled hidden>--Service Type--</option>
                                            <option [ngValue]="type.name" *ngFor="let type of categories">
                                                {{type.name}} </option>
                                        </select>
                                        <div *ngIf="service.errors?.required && (service.touched || isSubmitted)"
                                            class="text-danger">
                                            Service Type is required.
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input name="location" type="text" id="location" placeholder="Service Location*"
                                        class="form-control" #location="ngModel" [(ngModel)]="homeCareModel.location"
                                        required>
                                    <div *ngIf="location.errors?.required && (location.touched || isSubmitted)"
                                        class="text-danger">
                                        Service Location is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="details" rows="6" id="details" placeholder="Enter Property Details*"
                                        class="form-control" #propertyDetails="ngModel"
                                        [(ngModel)]="homeCareModel.propertyDetails" required></textarea>
                                    <div *ngIf="propertyDetails.errors?.required && (propertyDetails.touched || isSubmitted)"
                                        class="text-danger">
                                        Property Details is required.
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="send-btn">
                                    <button class="default-btn" (click)="sendHomeCare(homeCareForm)">Submit
                                        <span></span></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="overview-image text-center">
                    <img src="assets/img/homecareservices.jpg" alt="image" style="width: 90%;">
                </div>
            </div>
        </div>
    </div>
</section>