import { NgForm } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommomService } from 'src/app/commom.service';

@Component({
  selector: 'app-submitproperty',
  templateUrl: './submitproperty.component.html',
  styleUrls: ['./submitproperty.component.scss']
})
export class SubmitPropertyComponent implements OnInit {

  submitPropertyModel:any = {};
  isSubmitted = false;

  propertiesType: any = [
    "Apartment/Flat",
    "Builder Multi Unit",
    "Commercial Property",
    "Co-working",
    "Hospitality",
    "House/Villa",
    "Office",
    "Paying Guest",
    "Plot & Farmhouse",
    "Retail",
    "Showroom",
    "Storage",
    "Studio",
    "Warehouses"
  ];

  bhkList:any = [
    "1BHK",
    "2BHK",
    "3BHK",
    "4BHK",
  ];

  type:any;
  bhk:any;
  hostUrl: string;
  hostname: string;
  url: string;
  baseUrl: string;

  constructor(
    public commonService: CommomService,
    private toastr: ToastrService,
  ) {
    this.submitPropertyModel = {
      propertyType: '',
      bhk:''
    }
   }

  ngOnInit(): void {
    this.getBaseUrl();
  }

  getBaseUrl(){
    this.hostname = window.location.hostname
    if(this.hostname === "localhost") {
      this.url = "http://localhost:8000";
    }else if(this.hostname === "rentkochi.com") {
      this.url = "https://rentkochi.easerent.com"
    }
    else {
      this.url = "https://" + this.hostname;
    }
  }
  
  submitProperty(submitPropertyForm: NgForm) {
    this.baseUrl = this.url + '/api/EaseRent/addAllForm';
    if (submitPropertyForm.invalid) {
      this.isSubmitted = true;
      return;
    }

    this.isSubmitted = false;
    this.commonService.showLoading();
    const allForm = {
      contactUsBean: {
        email: null,
        message: null,
        name: null,
        phone: null,
        subject: null
      },
      commercialrentBean: {
        contactno: null,
        emailId: null,
        location: null,
        name: null,
        price: null,
        requirements: null,
        sqfeet: null,
        whatsapp: null
      },
      homeCareBean:{
        contactno: null,
        emailId: null,
        location: null,
        name: null,
        propertyDetails: null,
        service: null,
        whatsapp: null
      },
      postRequirementBean: {
        budget: null,
        contactNumber: null,
        emailId: null,
        location: null,
        name: null,
        requirements: null,
        whatsappNumber: null
      },
      careTakerBean:{
        bhk: null,
        contactno: null,
        description: null,
        emailId: null,
        name: null,
        propertyType: null,
        propertyname: null,
        whatsapp: null
      },
      serviceVillaBean: {
        bhk: null,
        contactno: null,
        days: null,
        emailId: null,
        fromdate: null,
        location: null,
        name: null,
        persons: null,
        price: null,
        requirements: null,
        todate: null,
        whatsapp: null
      },
      submitPropertyBean: this.submitPropertyModel
    }

    this.commonService.post(this.baseUrl, allForm).subscribe((res) => {
      if (res.Status === '200') {
        this.toastr.success("Success", "Thanks for your inquiry. Someone from our team will reach out to you soon !!")
      } else if (res.Status === '400') {
        this.toastr.error('Error', res.message)
      }
      submitPropertyForm.reset();
      this.submitPropertyModel = {
        propertyType: '',
        bhk:''
      }
      this.commonService.hideLoading();
    });
  }

}
