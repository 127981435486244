import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  showMButton:boolean = true;
  showLButton: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  showMore() {
    this.showMButton = false;
    this.showLButton = true;
  }

  showLess() {
    this.showMButton = true;
    this.showLButton = false;
  }
}
