import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeOneComponent } from './components/pages/home-one/home-one.component';
import { HomeTwoComponent } from './components/pages/home-two/home-two.component';
import { HomeThreeComponent } from './components/pages/home-three/home-three.component';
import { BlogPageComponent } from './components/pages/blog-page/blog-page.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { ComingSoonComponent } from './components/pages/comingsoon/comingsoon.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-terms/privacypolicy.component'; 
import { TermsConditions } from './components/pages/privacy-terms/termsconditions.component';
import { NestguruPrivacyComponent } from './components/pages/nestguru-privacy/nestguru-privacy.component';
import { PostRequirementComponent } from './components/pages/post-requirement/postrequirement.component';
import { SubmitPropertyComponent } from './components/pages/submit-property/submitproperty.component';
import { HomecareComponent } from './components/pages/homecare-services/homecare.component';
import { CommercialRentComponent } from './components/pages/commercial-rent/commercialrent.component'; 
import { FurnishedVillaComponent } from './components/pages/furnished-villa/furnishedvilla.component';
import { CaretakerComponent } from './components/pages/caretaker-services/caretaker.component';

const routes: Routes = [
    {path: '', component: HomeOneComponent},
    {path: 'home-two', component: HomeTwoComponent},
    {path: 'home-three', component: HomeThreeComponent},
    {path: 'blog', component: BlogPageComponent},
    {path: 'single-blog', component: BlogDetailsComponent},
    {path: 'comingsoon', component: ComingSoonComponent},
    {path: 'privacypolicy', component: PrivacyPolicyComponent},
    {path: 'termsconditions', component: TermsConditions},
    {path: 'nestguruprivacypolicy', component: NestguruPrivacyComponent},
    {path: 'post-requirement', component: PostRequirementComponent},
    {path: 'submit-property', component: SubmitPropertyComponent},
    {path: 'homecare-services', component: HomecareComponent},
    {path: 'commercial-rent', component: CommercialRentComponent},
    {path: 'furnished-villa', component: FurnishedVillaComponent},
    {path: 'caretaker-services', component: CaretakerComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }