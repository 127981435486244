import { NgForm } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommomService } from 'src/app/commom.service';

@Component({
  selector: 'app-postrequirement',
  templateUrl: './postrequirement.component.html',
  styleUrls: ['./postrequirement.component.scss']
})
export class PostRequirementComponent implements OnInit {

  postRequirementModel:any = {};
  isSubmitted = false;
  priceRange:any= [
    "0 -- 5K",
    "5K -- 10K",
    "10K -- 15K",
    "15K -- 20K",
    "20K -- 30K",
    "30K -- 40K",
    "40K -- 50K",
    ">50K",
  ]
  baseUrl: string;
  url: string;
  angularPath: any;
  hostname: string;
  port: string;
  hostUrl: string;
  fromPrice: any = 0;
  toPrice:any =0;


  constructor(
    public commonService: CommomService,
    private toastr: ToastrService,
  ) { 
    this.postRequirementModel = {
      budget: ''
    }
  }

  ngOnInit(): void {
    this.getBaseUrl();
  }

  getBaseUrl(){
    this.hostUrl = window.location.href;
    this.hostname = window.location.hostname;
    this.hostUrl = this.hostUrl.substring(0,this.hostUrl.length - 1);
    if(this.hostname === "localhost") {
      this.url = "http://localhost:8000";
    }else if(this.hostname === "rentkochi.com") {
      this.url = "https://rentkochi.easerent.com"
    }
    else {
      this.url = "https://" + this.hostname;
    }
  }

  onRentPriceChange(value: string){
    var range = value.split(" ");
    this.fromPrice = range[0];
    this.toPrice = range[2];
  }

  sendPostRequirement(postRequirementForm: NgForm) {
    this.baseUrl = this.url + '/api/EaseRent/addAllForm';
    if (postRequirementForm.invalid) {
      this.isSubmitted = true;
      return;
    }

    this.isSubmitted = false;
    this.commonService.showLoading();
    const allForm = {
      contactUsBean: {
        email: null,
        message: null,
        name: null,
        phone: null,
        subject: null
      },
      commercialrentBean: {
        contactno: null,
        emailId: null,
        location: null,
        name: null,
        price: null,
        requirements: null,
        sqfeet: null,
        whatsapp: null
      },
      homeCareBean:{
        contactno: null,
        emailId: null,
        location: null,
        name: null,
        propertyDetails: null,
        service: null,
        whatsapp: null
      },
      postRequirementBean:this.postRequirementModel,
      careTakerBean:{
        bhk: null,
        contactno: null,
        description: null,
        emailId: null,
        name: null,
        propertyType: null,
        propertyname: null,
        whatsapp: null
      },
      serviceVillaBean: {
        bhk: null,
        contactno: null,
        days: null,
        emailId: null,
        fromdate: null,
        location: null,
        name: null,
        persons: null,
        price: null,
        requirements: null,
        todate: null,
        whatsapp: null
      },
      submitPropertyBean: {
        advanceAmt: null,
        bhk: null,
        commercial: null,
        contactNumber: null,
        description: null,
        emailId: null,
        location: null,
        maintenance: null,
        owner: null,
        propertyName: null,
        propertyType: null,
        rent: null,
        residential: null,
        whatsapp: null
      }
    }

    this.commonService.post(this.baseUrl, allForm).subscribe((res) => {
      if (res.Status === '200') {
        this.toastr.success("Success", "Thanks for your inquiry. Someone from our team will reach out to you soon !!")
      } else if (res.Status === '400') {
        this.toastr.error('Error', res.message)
      }
      postRequirementForm.reset();
      this.postRequirementModel = {
        budget: ''
      }
      this.commonService.hideLoading();
    });
  }

}
